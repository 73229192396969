.dashboard-title {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #40438b;
  color: #ffffff;
  border-radius: 12px;
  font-size: large;
  font-weight: bold;
  padding: 5px;
}

.lead-module-button {
  display: flex;
  justify-content: end;
  color: #ffffff;
}

.lead-module-button:hover {
  cursor: pointer;
}
.lead-module-button div {
  background-color: #40438b;
  padding: 5px 15px;
  border-radius: 12px;
}

.lead-module-button div:hover {
  background-color: #393b79;
}

.breadcrums1:hover {
  cursor: pointer;
}

.dis-container {
  min-height: 70vh;
}

.dis-details {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: large;
  margin-bottom: 2%;
}
