.flexcontainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 2%;
}

.itemcenter {
  flex: 0 1 auto;
  width: 40%;
  position: absolute;
  /* new */
  left: 50%;
  transform: translateX(-50%);
}

.itemright {
  flex: 0 1 auto;
  width: 100px;
  margin-left: auto;
  /* new */
}

.filterContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1rem 0 1rem;
}

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  justify-content: center;
  padding-top: 10px;
}

.filterIcon {
  padding-left: 1rem;
}

.searchContainer {
  margin: auto;
  width: 65%;
  padding-top: 30px;
}

.navlink1:link {
  color: red;
}

/* visited link */
.navlink1:visited {
  color: green;
}

/* mouse over link */
.navlink1:hover {
  color: #1e1f63 ;
  /* color: whitesmoke; */
  /* background-color:#1e1f63 ; */
  font-size:17px ;
}

/* selected link */
.navlink1:active {
  color: blue;
}




@media only screen and (max-width: 600px) {
  .searchContainer {
    margin: auto;
    width: 100%;
    padding-top: 30px;
  }
  .searchCount {
    text-align: center;
  }
  .filterContainer {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 0 1rem 0 1rem;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1317px;
  }
}

@media screen and (max-width: 480px) {
  .videoCard {
    max-width: 200px;
  }
}

@media only screen and (max-width: 480px) {
  .chkbox {
    position: relative;
    width: 14px;
    height: 14px;
    margin-top: -6%;
    margin-left: 58%;
    margin-bottom: 15%;
    display: inline-block;
    /* vertical-align: middle; */
    cursor: pointer;
  }
  /* .whatspp {
    display: inline-block;
    margin-left: 10%;
  } */
}

@media only screen and (max-width: 480px) {
  .chkboxtwo {
    position: relative;
    width: 14px;
    height: 14px;
    margin-top: -6%;
    margin-left: 58%;
    margin-bottom: 15%;
    display: inline-block;
    /* vertical-align: middle; */
    cursor: pointer;
  }
}

@media only screen and (max-width: 480px) {
  .chkboxthree {
    position: relative;
    width: 14px;
    height: 14px;
    margin-top: -6%;
    margin-left: 58%;
    margin-bottom: 15%;
    display: inline-block;
    /* vertical-align: middle; */
    cursor: pointer;
  }
}
