.App {
    width: 100%;
    height: 1000px;
    display: flex;
    /* justify-content: center;  */
    align-items: center;
    /* margin-left: 19%;
    padding-left: 20px; */
    }
    
    table {
    border: 1px solid rgb(5, 5, 5);
    width: 750px;
    justify-content: center;        
    background-color: white;
    }
    
    th {
    text-align: center;
    border: 1px solid black;
    font-size: 15px;
    /* color: black; */
    }
    
    td {
    text-align: center;
    border: 1px solid black;
    /* color: black; */
    }
    
    .incentive-btn{
        margin-left: 47%;
    background-color: #40438b;
    color: white;
    border-radius: 10px;
    border: none;
    
    }